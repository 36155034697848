<template>
	<div>
		<Nav />
		<Breadcrumbs :items="crumbs"/>
		<div class="login">
      <div class="login__main">
        <div class="login__main--head text-center">
          <h1>Register Your Account</h1>
        </div>
	      <div class="login__main--body">
	      	<p class="mb-3 ml-2 caption">*Please register one account per entity, using the primary point-of-contact's email address and contact information.</p>
	      	<form @submit.prevent>
						<div>
			        <label for="firstName">Primary Contact's First Name</label>
			        <input type="text" placeholder="First Name" v-model.trim="contactForm.firstName" id="firstName" required @keyup="disabledCheck()" />
			      </div>
			      <div>
			        <label for="lastName">Primary Contact's Last Name</label>
			        <input type="text" placeholder="Last Name" v-model.trim="contactForm.lastName" id="lastName" required @keyup="disabledCheck()"/>
			      </div>
			      <div>
			        <label for="entity">Entity Name</label>
			        <input type="text" placeholder="California Department of ..." v-model.trim="contactForm.entity" id="entity" required @keyup="disabledCheck()" />
			      </div>
			      <div>
			        <label for="phone">Primary Contact's Phone</label>
			        <input type="text" placeholder="(###) ###-####" v-mask="'(###)-###-####'" v-model.trim="contactForm.phone" id="phone" @keyup="disabledCheck()"/>
			      </div>
			      <div>
			        <label for="email">Primary Contact's Email</label>
			        <input type="text" placeholder="you@email.com" v-model.trim="contactForm.email" id="email" required @keyup="disabledCheck()" />
			      </div>
			      <div>
			        <label for="password">Create a Password</label>
			        <input type="password" v-model.trim="contactForm.password" id="password" required @keyup="disabledCheck()" />
			      </div>
			      <div>
			        <label for="repassword">Re-Enter Your Password</label>
			        <input type="password" v-model.trim="contactForm.repassword" id="repassword" required @keyup="disabledCheck()" />
			        <transition name="fade">
			        	<div class="caption error" v-if="!match && contactForm.repassword.length >=3">Your passwords do not match.</div>
			        </transition>
			      </div>

			      <div>
			        <label for="anythingElse">Is there anything about your entity that we need to be aware of?</label>
			        <textarea id="anythingElse" cols="30" rows="4" v-model.trim.lazy="contactForm.anythingElse"></textarea>
			      </div>
			      
			      <button :disabled="isDisabled" class="btn btn__primary btn__large mt-3 mb-3" @click="submit()">
			        Register Account
			        <transition name="fade">
			          <span class="ml-2" v-if="performingRequest">
			          <i class="fa fa-spinner fa-spin"></i>
			          </span>
			        </transition>
			      </button>
					</form>
	      </div>
	    </div>
		</div>
    <Footer />
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import Nav from '@/components/Nav.vue'
import router from '@/router'
import Footer from '@/components/Footer.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
const fb = require('../../firebaseConfig.js')

export default {
	data: () => ({
		name: 'register',
    performingRequest: false,
    showForm: true,
    isDisabled: true,
    contactForm: {
    	firstName: '',
    	lastName: '',
    	entity: '',
      email: '',
      phone: '',
      password: '',
      repassword: '',
      anythingElse: ''
    },
    errors: []
  }),
  components: {
    Loader,
    Nav,
    Footer,
    Breadcrumbs
  },
  computed: {
    ...mapState(['currentUser', 'userProfile', 'customerProfile']),
    crumbs () {
      let crumbsArray = []
      let step1 = { title: "Home", to: { name: "home"}}
	    let step2 = { title: "Register", to: false}
      crumbsArray.push(step1)
      crumbsArray.push(step2)
      return crumbsArray
    },
    match() {
  		if ((this.contactForm.password && this.contactForm.repassword) && (this.contactForm.password == this.contactForm.repassword)) {
  			return true
  		} else {
  			return false
  		}
  	},
  },
  created() {
  	if (this.currentUser) {
  		router.go(-1)
  	}
  },
  methods: {
  	disabledCheck() {
			if (this.contactForm.firstName && this.contactForm.lastName && this.contactForm.entity && this.contactForm.email && this.contactForm.phone && this.match) {
  		return this.isDisabled = false
	  	} else {
	  		return this.isDisabled = true
  		}
  	},
	  submit() {
	    this.performingRequest = true
	    let contactForm = this.contactForm
    	fb.auth.createUserWithEmailAndPassword(this.contactForm.email, this.contactForm.password).then(UserCredential => {
    		console.log(UserCredential.user.uid)
    		fb.customersCollection.doc(UserCredential.user.uid).set({
    			id: UserCredential.user.uid,
          email: UserCredential.user.email,
          phone: contactForm.phone,
          firstName: contactForm.firstName,
          lastName: contactForm.lastName,
          entity: contactForm.entity,
          anythingElse: (contactForm.anythingElse || null),
          access: 'entity',
          created: fb.firestore.FieldValue.serverTimestamp(),
        })
    		.then(() => {
    			let currentUser = fb.auth.currentUser
        	currentUser.sendEmailVerification().then(function() {
					}).catch(function(error) {
					  console.log(error)
					})
          setTimeout(() => {
          	this.performingRequest = false
          	this.$router.push('/dashboard')
        	}, 3000)
				})
  		}).catch(err => {
        console.log(err)
        setTimeout(() => {
          this.performingRequest = false
        }, 3000)
        this.errors.push(err.message)
      })
	  }
	},
	beforeDestroy() {
		this.contactForm = {}
		delete this.contactForm
		this.isDisabled = true
		delete this.isDisabled
	}
}
</script>